<template>
  <v-card :flat="flat">
    <v-container fluid>
      <div class="text-subtitle-1">
        {{
          $t(
            'components.organisationSharingManage.' +
              modelName +
              '.introDefault'
          )
        }}
      </div>
      <v-row>
        <v-col cols="12" sm="6">
          <v-autocomplete
            v-model="defaultOrganisations"
            :loading="isLoading"
            :items="filteredDefaultOrganisations"
            :label="
              $t('components.organisationSharingManage.organisations.label')
            "
            item-value="id"
            item-text="name"
            deletable-chips
            chips
            multiple
          >
            <template #selection="{ item, selected }">
              <v-chip :input-value="selected" color="productColor" dark>
                <span v-text="item.name" />
                <v-icon right> mdi-star </v-icon>
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <div class="text-subtitle-1">
        {{
          $t(
            'components.organisationSharingManage.' + modelName + '.introCommon'
          )
        }}
      </div>
      <v-row>
        <v-col cols="12" sm="6">
          <v-autocomplete
            v-model="commonOrganisations"
            :loading="isLoading"
            :items="filteredCommonOrganisations"
            :label="
              $t('components.organisationSharingManage.organisations.label')
            "
            item-value="id"
            item-text="name"
            deletable-chips
            chips
            multiple
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { mapState } from 'vuex';

export default {
  props: {
    modelName: {
      type: String,
      required: true,
    },
    modelDetailName: {
      type: String,
      required: false,
      default: () => null,
    },
    flat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    // Dynamically access the model from Vuex state
    model() {
      return (
        (this.this.modelDetailName
          ? this.$store.state[this.modelName][this.modelDetailName]
          : this.$store.state[this.modelName]) || {}
      );
    },

    ...mapState('account', ['companyOrganisations']),

    organisations() {
      return this.companyOrganisations;
    },

    commonOrganisations: {
      get() {
        return (
          (this.modelDetailName
            ? this.$store.state[this.modelName][this.modelDetailName]
            : this.$store.state[this.modelName]
          ).common_organisations || []
        );
      },
      set(value) {
        this.$store.dispatch(`${this.modelName}/updateOrganisations`, {
          common_organisations: value,
        });
        this.$emit('changed', { common_organisations: value });
      },
    },
    defaultOrganisations: {
      get() {
        return (
          (this.modelDetailName
            ? this.$store.state[this.modelName][this.modelDetailName]
            : this.$store.state[this.modelName]
          ).default_organisations || []
        );
      },
      set(value) {
        this.$store.dispatch(`${this.modelName}/updateOrganisations`, {
          default_organisations: value,
        });
        this.$emit('changed', { default_organisations: value });
      },
    },

    // Filter organisations not included in common organisations
    filteredDefaultOrganisations() {
      return (
        this.organisations?.filter(
          (organisation) => !this.commonOrganisations.includes(organisation.id)
        ) || []
      );
    },

    // Filter organisations not included in default organisations
    filteredCommonOrganisations() {
      return (
        this.organisations?.filter(
          (organisation) => !this.defaultOrganisations.includes(organisation.id)
        ) || []
      );
    },
  },

  async mounted() {
    if (
      this.$acl.check('editClientsOrganisationManagement') &&
      !this.companyOrganisations
    ) {
      this.isLoading = true;
      await this.$store.dispatch('account/loadCompanyOrganisations');
      this.isLoading = false;
    }
  },
};
</script>
