/* ============
 * Actions for the ad module
 * ============
 *
 * The actions that are available on the
 * ad module.
 */

import AdResource from '@/resources/AdResource';
import AccountResource from '@/resources/AccountResource';
import AdProxy from '@/proxies/AdProxy';
import * as types from './mutation-types';

/* Resource actions => analytics api */

export const getAds = ({ commit }) =>
  new Promise((resolve, reject) => {
    new AdResource()
      .getAds()
      .then((response) => {
        commit(types.SET_ADS_ANALYTICS, response);
        resolve();
      })
      .catch((response) => {
        reject(response);
      });
  });

/* Proxy actions => pinpollcom api */

export const save = ({ rootState }, ad) =>
  new Promise((resolve, reject) => {
    const params = {
      ...ad,
      settings: ad.settings?.serialize(),
      custom_targeting: ad.custom_targeting || null,
      image: rootState.image.postData,
    };
    new AdProxy()
      .create(params)
      .then((response) => {
        resolve(response);
      })
      .catch((response) => {
        reject(response);
      });
  });

export const update = ({ rootState }, { id, ad }) =>
  new Promise((resolve, reject) => {
    const params = {
      ...ad,
      settings: ad.settings?.serialize(),
      custom_targeting: ad.custom_targeting || null,
      image: rootState.image.postData,
    };
    new AdProxy()
      .update(id, params)
      .then((response) => {
        resolve(response);
      })
      .catch((response) => {
        reject(response);
      });
  });

export const destroy = ({ commit }, id) =>
  new Promise((resolve, reject) => {
    new AdProxy()
      .destroy(id)
      .then(() => {
        commit(types.DELETE, id);
        resolve();
      })
      .catch((response) => {
        reject(response);
      });
  });

export const datatables = ({ commit }, params) =>
  new Promise((resolve, reject) => {
    new AdProxy()
      .datatables(params)
      .then(async (response) => {
        try {
          response.data.forEach((ad) => {
            ad.organisations = ad.organisations
              ? JSON.parse(ad.organisations)
              : [];
          });
          const auditInfoResponse = await new AccountResource().entityInformationBatch(
            'App\\Models\\AdDetail',
            response.data.map((ad) => ad.ad_id)
          );
          response.data.forEach((ad) => {
            ad.auditInformation = auditInfoResponse[ad.ad_id];
          });
        } catch (e) {
          console.error(e);
        }
        commit(types.SET_ADS, response);
        resolve();
      })
      .catch((response) => {
        reject(response);
      });
  });

export const updateAdParams = ({ commit }, adData) => {
  commit(types.UPDATE_AD_PARAMS, adData);
};

export const getAdById = ({ commit }, id) =>
  new Promise((resolve, reject) => {
    new AdProxy()
      .getAdById(id)
      .then((response) => {
        commit(types.SET_AD, response.data);
        resolve();
      })
      .catch((response) => {
        reject(response);
      });
  });

export const getElementsOfUser = ({ commit }, params) =>
  new Promise((resolve, reject) => {
    new AdProxy()
      .getElementsOfUser(params)
      .then((response) => {
        commit(types.SET_ELEMENTS_QUESTIONS_ANSWERS, response);
        resolve();
      })
      .catch((response) => {
        reject(response);
      });
  });

export const getPixelsOfUser = ({ commit }) =>
  new Promise((resolve, reject) => {
    new AdProxy()
      .getPixelsOfUser()
      .then((response) => {
        commit(types.SET_PIXELS, response);
        resolve();
      })
      .catch((response) => {
        reject(response);
      });
  });

export const getAdTargetingsOfUser = ({ commit }) =>
  new Promise((resolve, reject) => {
    new AdProxy()
      .getAdTargetingsOfUser()
      .then((response) => {
        commit(types.SET_AD_TARGETINGS, response);
        resolve();
      })
      .catch((response) => {
        reject(response);
      });
  });

export const updateOrganisations = async ({ commit }, data) => {
  commit(types.SET_ORGANISATIONS, data);
};

export default {
  getAds,
  save,
  update,
  destroy,
  datatables,
  getAdById,
  updateAdParams,
  getElementsOfUser,
  getPixelsOfUser,
  getAdTargetingsOfUser,
  updateOrganisations,
};
