/* ============
 * Getters for the account module
 * ============
 *
 * The getters that are available on the
 * account module.
 */

export default {
  hasSingleAnalyticsDomain(state) {
    if (!state.user || !state.user.domains) return null;
    const domains = state.user.domains.filter((domain) => domain.analytics);
    if (domains.length === 1) {
      return domains[0].title.replace('http://', '').replace('https://', '');
    }
    return null;
  },
  canSeeAllDomains(state) {
    if (!state.user) return false;
    return state.access.includes('GLOBAL_MONITORING.VIEW');
  },
  analyticsDomains(state) {
    if (!state.user || !state.user.domains) return [];
    return state.user.domains
      .filter((domain) => domain.analytics)
      .map((domain) =>
        domain.title.replace('http://', '').replace('https://', '')
      );
  },
  isAdmin(state) {
    return state.user.permissions.includes('MODULE_ADMIN.VIEW');
  },
  /**
   * Checks if current user is member of an organisation
   *
   * @param {object} state - current vuex state
   *
   * @returns {boolean} indicator whether user is member of an organisation
   */
  isInOrganisation(state) {
    return !!state.user.default_organisation_id;
  },
};
