<template>
  <v-card class="mx-3 mt-2">
    <v-fab-transition>
      <v-btn
        fab
        color="productColor"
        dark
        absolute
        top
        left
        @click="showAddAdDialog"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-card-text>
      <v-row class="mt-1">
        <v-col cols="12" sm="4" lg="3">
          <v-combobox
            v-model="search"
            :label="$t('components.searchBox.searchLabel')"
            multiple
            autofocus
            chips
            clearable
            deletable-chips
            prepend-inner-icon="mdi-magnify"
            append-icon=""
            @update:search-input="searchUpdated"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0" cols="12">
          <v-data-table
            :headers="adaptedHeaders"
            :items="ads"
            :loading="loading"
            :options.sync="options"
            :server-items-length="totalAds"
            :footer-props="{ 'items-per-page-options': rowsPerPageItems }"
            @click:row="rowSelected"
          >
            <template #no-data>
              <v-responsive :aspect-ratio="2.5">
                <v-container fluid fill-height>
                  <v-layout column align-center justify-center>
                    <h3 class="text-h4">
                      {{ $t('components.datatable.noData') }}
                    </h3>
                    <v-btn
                      class="ma-4"
                      color="productColor"
                      large
                      @click.stop="showAddAdDialog()"
                    >
                      <span class="white--text">
                        {{ $t('components.ads.table.buttons.create') }}
                      </span>
                    </v-btn>
                  </v-layout>
                </v-container>
              </v-responsive>
            </template>

            <template #[`item.image`]="{ item }">
              <v-avatar size="35px">
                <v-img
                  :src="
                    item.image
                      ? getFullPath(item.image)
                      : helpers.getPlaceholderImage()
                  "
                  alt="Avatar"
                />
              </v-avatar>
            </template>

            <template #[`item.name`]="{ item }">
              {{ item.name }}
            </template>
            <template #[`item.organisations`]="{ item }">
              <div class="pb-1">
                <v-chip
                  v-for="organisation in item.organisations"
                  :key="organisation.id"
                  :color="organisation.default ? 'productColor' : 'gray'"
                  :dark="!!organisation.default"
                  small
                  class="mr-2 mt-1"
                >
                  {{ organisation.name }}
                  <v-icon v-if="organisation.default" right small>
                    mdi-star
                  </v-icon>
                </v-chip>
              </div>
            </template>
            <template #[`item.is_static`]="{ item }">
              {{
                item.is_static
                  ? $t('components.ads.table.type.static')
                  : $t('components.ads.table.type.dynamic')
              }}
            </template>
            <template #[`item.approved`]="{ item }">
              {{
                item.is_static || item.approved
                  ? $t('components.ads.table.approved.yes')
                  : $t('components.ads.table.approved.no')
              }}
            </template>
            <template #[`item.clickthrough_rate`]="{ item }">
              {{ item.clickthrough_rate | percent }}
            </template>
            <template #[`item.created_at`]="{ item }">
              {{ item.created_at | formatDateTimeMinutes }}
            </template>
            <template #[`item.activity`]="{ item }">
              <audit-information
                v-if="showAuditInformation && item.auditInformation"
                :entity-information="item.auditInformation"
              />
            </template>
            <template #[`item.actions`]="{ item }">
              <v-tooltip v-if="isAdmin || enableDuplicate" bottom>
                <template #activator="{ on }">
                  <v-btn
                    text
                    icon
                    color="productColor"
                    v-on="on"
                    @click.stop="showDuplicateAdDialog(item)"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('components.ads.table.buttons.duplicate') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn
                    text
                    icon
                    color="error"
                    :disabled="!enableDelete(item)"
                    v-on="on"
                    @click.stop="setAdDeleteDialog(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('components.ads.table.buttons.delete') }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <dialog-delete
      :show="showDeleteDialog"
      :model-name="$t('models.single.ad')"
      :name="currentAdNameToDelete"
      :loading="deletingProgress"
      @confirmed="deleteAd"
      @close-dialog="setAdDeleteDialog"
    />
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import helpers from '@/utils/helpers';
import constants from '@/utils/constants';
import DialogDelete from '@/components/DialogDelete';
import AuditInformation from '@/components/AuditInformation';

export default {
  components: { DialogDelete, AuditInformation },
  filters: {
    percent(value) {
      if (value === null) {
        return '-';
      }
      if (numeral.locale() === 'en') {
        return numeral(value).format('0.00%');
      }
      return numeral(String(value).replace('.', ',')).format('0.00%');
    },
  },
  data() {
    return {
      helpers,
      headerLength: 9,
      searchName: '',
      searchUrl: '',
      searchEmail: '',
      searchId: NaN,
      searchActive: false,
      lastSortName: 'ad.id',
      headers: [
        {
          text: this.$t('components.ads.table.headers.id'),
          value: 'id',
        },
        {
          text: this.$t('components.ads.table.headers.image'),
          value: 'image',
        },
        {
          text: this.$t('components.ads.table.headers.email'),
          value: 'email',
        },
        {
          text: this.$t('components.ads.table.headers.name'),
          value: 'name',
        },
        {
          text: this.$t('components.ads.table.headers.organisations'),
          value: 'organisations',
          sortable: false,
        },
        {
          text: this.$t('components.ads.table.headers.type'),
          value: 'is_static',
        },
        {
          text: this.$t('components.ads.table.headers.approved'),
          value: 'approved',
        },
        {
          text: this.$t('components.ads.table.headers.ctaClicks'),
          value: 'cta_clicks',
        },
        {
          text: this.$t('components.ads.table.headers.ctr'),
          value: 'clickthrough_rate',
        },
        {
          text: this.$t('components.ads.table.headers.views'),
          value: 'views',
        },
        {
          text: this.$t('components.ads.table.headers.created_at'),
          value: 'created_at',
        },
        {
          text: this.$t('components.ads.table.headers.activity'),
          value: 'activity',
          sortable: false,
        },
        {
          text: this.$t('components.ads.table.headers.actions'),
          value: 'actions',
          align: 'left',
          sortable: false,
          width: 120,
        },
      ],
      rowsPerPageItems: [5, 10, 25, 50, 100],
      options: {
        page: 1,
        sortBy: ['ad.id'],
        sortDesc: [true],
        itemsPerPage: 10,
      },
      loading: false,
      search: [],
      activeFilters: {},
      deletingProgress: false,
      showDeleteDialog: false,
      currentAdNameToDelete: undefined,
      currentAdIdToDelete: undefined,
    };
  },
  computed: {
    ...mapState('ad', {
      ads: (state) => state.ads,
      totalAds: (state) => state.total_ads,
    }),
    ...mapState('account', ['companyOrganisations']),
    isAdmin() {
      return this.$acl.check('viewModuleAdmin');
    },
    enableDuplicate() {
      return this.$acl.check('duplicateAds');
    },

    showOrganisations() {
      return (
        this.$acl.check('viewOrganisationAds') ||
        this.$acl.check('viewModuleAdmin')
      );
    },
    showAuditInformation() {
      return this.$acl.check('viewAcitivityLog');
    },
    showCreateButton() {
      return this.$acl.check('createAds');
    },
    adaptedHeaders() {
      if (this.$acl.check('viewModuleAdmin')) {
        return this.headers;
      }
      return this.headers.filter((item) => {
        if (['email', 'image', 'organisations'].includes(item.value)) {
          return !!this.showOrganisations;
        }
        if (['activity'].includes(item.value)) {
          return !!this.viewAcitivityLog;
        }
        return true;
      });
    },
    /**
     * Search Params
     *
     * @returns {object} searchParams
     */
    searchParams() {
      const adOptions = ['id', 'created_at'];
      return {
        search: this.search,
        options: {
          ...this.options,
          sortBy: this.options.sortBy.map((item) =>
            adOptions.includes(item) ? `ad.${item}` : item
          ),
        },
        filters: this.activeFilters,
        page: this.options.page, // page param is needed in request so laravel is automatically parsing it
      };
    },
  },
  watch: {
    options() {
      this.loading = true;
      helpers.setPagination('ad', this.options.rowsPerPageItems);
      this.getDataFromApiDebounced();
    },
  },
  created() {
    this.$bus.listen('data-updated', this.getDataFromApi);
  },
  async mounted() {
    this.options.rowsPerPageItems = helpers.getPagination('ad');
  },
  destroyed() {
    this.$bus.remove('data-updated');
  },
  methods: {
    enableDelete(item) {
      return (
        this.isAdmin ||
        (item.isOwn
          ? this.$acl.check('crudAds')
          : this.$acl.check('deleteOrganisationAds'))
      );
    },
    getFullPath(img) {
      return `${constants.CDN_STORAGE}/user/${img}`;
    },
    getDataFromApiDebounced: _.debounce(function search(text) {
      this.getDataFromApi(text);
    }, 500),
    /**
     * Search is updated
     * first reset page to 1
     * then call api
     *
     * @param {string} searchText - text to search for
     *
     * @returns {void}
     */
    searchUpdated(searchText) {
      this.options.page = 1;
      this.getDataFromApiDebounced(searchText);
    },
    async getDataFromApi(searchText = null) {
      this.loading = true;

      // add current searchText to search array (if any)
      const search = _.clone(this.search);
      if (searchText) search.push(searchText);

      const params = this.searchParams;
      params.search = search;
      try {
        await this.$store.dispatch('ad/datatables', params);
      } catch (err) {
        console.log(err);
        this.$bus.fire('show-snackbar', {
          message: err.error ? err.error && err.error.message : err,
          options: {
            color: 'error',
            timeout: 0,
          },
        });
      }
      this.loading = false;
    },
    showAddAdDialog() {
      this.$bus.fire('show-ad-dialog-create-mode');
    },
    /**
     * Called when row is selected which opens the pixel dialog
     *
     * @param {object} item - selected item
     *
     * @returns {void}
     */
    rowSelected(item) {
      this.$store.commit('ad/DISABLE_QUESTIONS_AND_ANSWERS');
      this.$bus.fire('show-ad-dialog-edit-mode', {
        id: item.id,
        deleteFunction: this.setAdDeleteDialog,
      });
    },
    showDuplicateAdDialog(item) {
      this.$bus.fire('show-ad-dialog-create-mode', item);
    },
    setAdDeleteDialog(ad) {
      if (ad) {
        this.currentAdNameToDelete = ad.name;
        this.currentAdIdToDelete = ad.element_id || ad.id;
        this.showDeleteDialog = true;
      } else {
        this.currentAdNameToDelete = null;
        this.currentAdIdToDelete = null;
        this.showDeleteDialog = false;
      }
    },
    async deleteAd() {
      this.deletingProgress = true;
      try {
        await this.$store.dispatch('ad/destroy', this.currentAdIdToDelete);
        // fire event that an ad was assigned
        this.$bus.fire('data-updated');
      } catch (err) {
        console.error(err);
      }
      this.deletingProgress = false;
      this.setAdDeleteDialog();
      this.$bus.fire('hide-ad-dialog');
    },
  },
};
</script>
