<template>
  <v-tooltip v-if="entityInformation.modified_at" bottom>
    <template #activator="{ on }">
      <v-btn
        :href="getDatatableLink()"
        target="_blank"
        icon
        color="productColor"
        v-on="on"
        @click.stop=""
      >
        <v-icon>mdi-history</v-icon>
      </v-btn>
    </template>
    <span v-if="entityInformation.created_at">
      {{ `${$t('components.auditLink.created_at')}: ` }}
      {{ entityInformation.created_at | formatDateTime }}
      {{
        `${$t('components.auditLink.by')} ${entityInformation.created_email}`
      }}
      <br />
    </span>
    <span v-else-if="!entityInformation.created_at">
      {{
        `${$t('components.auditLink.created_at')}: ${$t(
          'components.auditLink.notAvailable'
        )}`
      }}
      <br />
    </span>
    <span
      >{{ `${$t('components.auditLink.modified_at')}: ` }}
      {{ entityInformation.modified_at | formatDateTime }}
      {{
        `${$t('components.auditLink.by')} ${entityInformation.modified_email}`
      }}</span
    >
  </v-tooltip>
</template>
<script>
import constants from '@/utils/constants';

export default {
  props: {
    customId: {
      type: Number,
      required: false,
      default: 0,
    },
    entityInformation: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      mapping: {
        placeAds: 'App\\Models\\AdDetail',
      },
    };
  },
  methods: {
    /**
     * Get link to Account datatable with filter query
     * @returns {string}
     */
    getDatatableLink() {
      const filter = {};
      filter.type = this.mapping[this.$route.name];
      filter.id = Number.parseInt(this.entityInformation.auditable_id, 10);
      return `${
        constants.ACCOUNT_URL
      }/security/activities?${new URLSearchParams(filter).toString()}`;
    },
  },
};
</script>
