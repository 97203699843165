/* ============
 * Mutations for the account module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */
import { localize } from 'vee-validate';
import { i18n } from '@/plugins/vue-i18n';
import * as types from './mutation-types';
import { getInitialState } from './state';
import constants from '@/utils/constants';

export default {
  [types.SET](state, account) {
    // set user
    state.user = account;

    // if the user has an avatar image save the full path
    if (account.image) {
      state.user.image = `${constants.CDN_STORAGE}/user/${account.image}`;
    }

    // set permissions to access array
    state.access = account.permissions;

    // add virtual permission AUTHENTICATED
    state.access.push('AUTHENTICATED');

    // save permissions in localStorage
    localStorage.setItem('permissions', JSON.stringify(state.access));
  },

  [types.SET_LANGUAGE](state) {
    const accountLanguage =
      (state.user && state.user.language) || localStorage.language || 'en';
    if (state.availableLocales.includes(accountLanguage)) {
      localStorage.language = accountLanguage;
      i18n.locale = accountLanguage;
      moment.locale(accountLanguage);
      numeral.locale(accountLanguage);
      localize(accountLanguage);
    }
  },

  [types.SET_ACCESS](state, access = ['public']) {
    state.access = access;

    // save current permission in localStorage
    localStorage.setItem('permissions', JSON.stringify(state.access));
  },

  [types.UPDATE_DISPLAY_SETTING](state, data) {
    state.displaySettings[data.prop] = data.value;
  },

  [types.UPDATE_DARK_MODE](state, newState) {
    state.displaySettings.showDarkModeBigboard = newState;
    if (localStorage) {
      localStorage.setItem('dark-mode', JSON.stringify(newState));
    }
  },

  [types.SET_DISPLAY_SETTINGS](state, data) {
    state.displaySettings = {
      ...state.displaySettings,
      ...data,
    };
  },
  [types.RESET_STATE](state) {
    // reset state to initial state
    Object.assign(state, getInitialState());
  },
  [types.SET_BIGBOARD_STATE](state, newState) {
    state.bigBoardEnabled = newState;
  },
  [types.SET_COMPANY_ORGANISATIONS](state, data) {
    state.companyOrganisations = data;
  },
};
