/* ============
 * Actions for the account module
 * ============
 *
 * The actions that are available on the
 * account module.
 */

import AccountResource from '@/resources/AccountResource';
import AuthProxy from '@/proxies/AuthProxy';
import * as types from './mutation-types';

export const me = ({ dispatch, commit }) =>
  new Promise((resolve, reject) => {
    new AuthProxy()
      .me()
      .then((response) => {
        commit(types.SET, response.data);
        commit(types.SET_LANGUAGE);
        resolve();
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/logout', null, { root: true });
        reject(new Error('Error during checking account!'));
      });
  });

export const updateDisplaySettings = ({ state, dispatch }) => {
  dispatch('updateSettings', {
    prop: 'displaySettings',
    value: state.displaySettings,
  });
};

export const loadDisplaySettings = async ({ commit, dispatch }) => {
  const data = await dispatch('loadSetting', 'displaySettings');
  if (data && data.value) {
    let displaySettings = null;
    try {
      displaySettings = JSON.parse(data.value);
      if (displaySettings) commit(types.SET_DISPLAY_SETTINGS, displaySettings);
    } catch (e) {
      console.error(e);
    }
  }
};

export const updateSettings = (context, payload) => {
  new AccountResource()
    .storeSetting(payload.prop, JSON.stringify(payload.value))
    .catch((error) => console.warn(`${payload.prop} not saved!`, error));
};

export const loadSetting = (context, setting) =>
  new Promise((resolve, reject) => {
    new AccountResource()
      .getSetting(setting)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.warn(`${setting} could not be retrieved!`, error);
        reject(error);
      });
  });

export const loadCompanyOrganisations = async ({ commit }) => {
  const response = await new AccountResource().companyOrganisations();
  commit(types.SET_COMPANY_ORGANISATIONS, response);
};

export default {
  me,
  updateDisplaySettings,
  updateSettings,
  loadDisplaySettings,
  loadSetting,
  loadCompanyOrganisations,
};
